import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/layout';
import Seo from '../components/seo';
import '../styles/style.sass';
import MainScreenSecond from '../components/UI/MainScreenSecond/MainScreenSecond';
import Instagram from '../components/Layout/Instagram/Instagram';
import ArticlesCatalogPage from '../components/Pages/ArticlesCatalogPage/ArticlesCatalogPage';

// import dataBlog from '../db/dataBlog';

const Articles = ({data}) => {
  const { t } = useTranslation();

  const crumbsNav = [
    {
      id: 1,
      title_ua: 'Головна',
      title_ru: 'Главная',
      title_en: 'Home',
      to: '/',
    },
    {
      id: 2,
      title_ua: 'Статті',
      title_ru: 'Статьи',
      title_en: 'Articles',
      to: '/articles/',
    },
  ];

  const mainTitle = {
    title_ua: 'Новини / Статті',
    title_ru: 'Новости / Статьи',
    title_en: 'News / Articles',
  };

  return (
    <Layout whitwHeader={'active'}>
      <Seo title={t('Home')} />
      <MainScreenSecond
        mainData={data.strapiArticles}
        crumbsNav={crumbsNav}
        mainTitle={mainTitle}
      />
      <ArticlesCatalogPage dataArticles={data.allStrapiBlog.nodes} />
      <Instagram />
    </Layout>
  );
};

export default Articles;

export const query = graphql`
query ArticlePage($language: String!) {
  strapiArticles {
    seo_title_ua
    seo_title_ru
    seo_title_en
    seo_description_ua
    seo_description_ru
    seo_description_en
    title_ua
    title_ru
    title_en
    image {
      localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
  }
  allStrapiBlog {
    nodes {
    title_ua
    title_ru
    title_en
    slug
    data
    image {
      localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
    }
  }
}
  locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}
`